import styled from '@emotion/styled'


const SitePageStyles = styled.div`
  line-height: 1.4;
  p {
    font-weight: lighter;
  }
`

export default SitePageStyles
